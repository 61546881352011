<template>
    <div class="form-page regist-page">
        <div class="flex-center" style="position: fixed; right: 10px;top:12px;z-index: 2000;line-height:0;font-size:0 ">
            <div style="line-height: 1;font-size:0" @click="openService">
                <div class="christ-a">
                    <img src="../assets/pictures/mykefu.png" style="width: 30px;height: 30px;margin-right: 10px">
                </div>
              </div>
            <router-link class="btn lang-btn" to="/lang" v-if="select_lang > 0">
                <span class="iconfont icon-yuyan"></span>
                <span>{{$t('选择语言')}}</span>
            </router-link>
        </div>
        <van-nav-bar :border="false" :fixed="true" :title="$t('注册')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
<!--        <van-notify className="bellnotify" v-model="notifyshow" background='#ffffff'>-->
<!--            <div class="bell-tip">-->
<!--                <van-icon name="bell" style="margin-right: 8px;" />-->
<!--                <div class="flex_bd">-->
<!--                    <div class="bell-tt">{{$t('现在就创建账户')}}</div>-->
<!--                    <div class="bell-info text-gray">{{$t('30秒快速开户')}}</div>-->
<!--                </div>-->
<!--                <van-icon name="close" @click.native="notifyshow = false"/>-->
<!--            </div>-->
<!--        </van-notify>-->
        <div class="login-panel" style="padding-top: 40px">
            <div class="login-head">
                <van-popover v-model="showPopover" trigger="click">
                    <div class="filter-dropdown">
                        <van-radio-group v-model="radio">
                            <van-cell-group>
                                <van-cell :title="$t('邮箱')" clickable @click="changeRadio('1')" v-if="mail_register == 1">
                                    <template #right-icon>
                                        <van-radio name="1" checked-color="#20b759" />
                                    </template>
                                </van-cell>
                                <van-cell :title="$t('手机号')" clickable @click="changeRadio('2')">
                                    <template #right-icon>
                                        <van-radio name="2" checked-color="#20b759"/>
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                    <template #reference>
                        <div class="l-button">
                            <span>{{labelV}}</span><span class="iconfont icon-tree-node"></span>
                        </div>
                    </template>
                </van-popover>
            </div>
            <van-form class="login-form">
                <van-field v-model="username" :placeholder="$t('请输入用户名')"/>

                <div v-if="radio == '1'">
                    <van-field v-model="phone" center :placeholder="$t('请输入Email')" >
                    </van-field>
                    <template v-if="mail_captcha == '1'">
                        <van-field v-model="code"
                                   :placeholder="$t('请输入验证码')">
                            <template #button>
                                <van-button
                                        class="get-code" :label="$t('验证码')"
                                        :disabled="timeout != 0"
                                        @click="handleClickSendCode"
                                >{{timeout > 0 ? timeout : $t('获取验证码')}}
                                </van-button>
                            </template>
                        </van-field>
                    </template>
                    <template v-else>
                        <van-field v-model="code" :placeholder="$t('请输入验证码')">
                            <template #right-icon>
                                <div class="link-code">
                                    <div class="link-code"><img :src="captcha_img" @click="getCaptcha"></div>
                                </div>
                            </template>
                        </van-field>
                    </template>
                </div>

                <div v-if="radio == '2'">
                    <van-field v-model="phone" center :placeholder="$t('请输入你的手机号码')" type="digit">
                        <template #label>
                            <div class="sw-label" @click="showPicker = true">
                                <span class="lan-n">{{defaultCountry.value}}</span>
                                <span class="iconfont icon-tree-node"></span>
                            </div>
                        </template>
                    </van-field>
                    <template v-if="register_captcha == '1'">
                        <van-field v-model="code"
                                   :placeholder="$t('请输入验证码')">
                            <template #button>
                                <van-button
                                        class="get-code" :label="$t('验证码')"
                                        :disabled="timeout != 0"
                                        @click="handleClickSendCode"
                                >{{timeout > 0 ? timeout : $t('获取验证码')}}
                                </van-button>
                            </template>
                        </van-field>
                    </template>
                    <template v-else>
                        <van-field v-model="code" :placeholder="$t('请输入验证码')">
                            <template #right-icon>
                                <div class="link-code">
                                    <div class="link-code"><img :src="captcha_img" @click="getCaptcha"></div>
                                </div>
                            </template>
                        </van-field>
                    </template>
                </div>


                <van-field v-model="password" center :placeholder="$t('请输入密码')" v-if="sWpwd">
                    <template #button>
                        <van-icon name="eye" size="20px" color="#4ab4dd" @click.native="sWpwd = false"/>
                    </template>
                </van-field>
                <van-field type="password" v-model="password" center :placeholder="$t('请输入密码')" v-else>
                    <template #button>
                        <van-icon name="closed-eye" size="20px" color="#4ab4dd" @click.native="sWpwd = true"/>
                    </template>
                </van-field>

                <van-field v-model="password2" center :placeholder="$t('请输入确认密码')" v-if="sWpwd">
                    <template #button>
                        <van-icon name="eye" size="20px" color="#4ab4dd" @click.native="sWpwd = false"/>
                    </template>
                </van-field>
                <van-field type="password" v-model="password2" center :placeholder="$t('请输入确认密码')" v-else>
                    <template #button>
                        <van-icon name="closed-eye" size="20px" color="#4ab4dd" @click.native="sWpwd = true"/>
                    </template>
                </van-field>
                <van-field v-model="share_code" :placeholder="$t('请输入邀请码')"/>
                <div class="agreement-link">
                    <van-checkbox v-model="agree">
                        {{$t('我已阅读并同意')}}
                    </van-checkbox>
                    <div @click="agreeShow = true" class="text-blue">{{$t('《用户协议》')}}</div>
                </div>
            </van-form>
            <div class="form-foot-button">
                <van-button @click="handleClickRegister" type="primary" block>{{$t('注册')}}</van-button>
            </div>
            <div class="form-item"> {{$t('已有账户?')}} <router-link class="text-blue" to="/login"> {{$t('登录')}}</router-link></div>
        </div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="allCountries"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                    value-key="text"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            >
            </van-picker>
        </van-popup>
        <van-popup v-model="agreeShow" round position="bottom" closeable>
            <div class="agree-content" v-html="agreeContent">
            </div>
        </van-popup>
        <div class="version">
            {{$t('版本号')}}
          </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                agree:false,
                agreeShow:false,
                agreeContent:'',
                radio: '2',
                labelV: this.$t('手机号'),
                sWpwd: false,
                showPopover: false,
                showPicker:false,
                notifyshow:false,
                show: false,
                select_lang: false,
                username: "",
                phone: "",
                password: "",
                password2: "",
                share_code: "",
                register_captcha: 0,
                mail_captcha: 0,
                mail_register: 0,
                timeout: 0,
                captcha_key: "",
                captcha_code: "",
                captcha_img: '',
                timeout_limit: 60,
                code: '',
                sms_captcha: 0,
                defaultCountry: '+92',
                allCountries: [
                    {text: 'Pakistan (‫پاکستان‬‎) +92', value: '+92'},
                    {text: 'India (भारत) +91', value: '+91'},
                    {text: 'Bangladesh (বাংলাদেশ) +880', value: '+880'},
                    {text: 'United States +1', value: '+1'},
                    {text: 'Australia +61', value: '+61'},
                    {text: 'Singapore +65', value: '+65'},
                    {text: 'United Kingdom +44', value: '+44'},
                    {text: 'Japan +81', value: '+81'},
                    {text: 'South Korea (대한민국) +82', value: '+82'},
                    {text: 'Saudi Arabia (‫المملكة العربية السعودية‬‎) +966', value: '+966'},
                    {text: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎) +971', value: '+971'},
                ],
                metaInfo: {
                    title: '資料維護',
                }
            }
        },
        methods: {
            openService(){
                window._vue.openService();
            },
            changeRadio(val){
                if(val == '1'){
                    this.labelV = this.$t('邮箱');
                }else{
                    this.labelV = this.$t('手机号');
                }
                this.radio = val;
                this.phone = '';
                this.showPopover = false
            },
            showNotify() {
                this.notifyshow = true;
                setTimeout(() => {
                    this.notifyshow = false;
                }, 5000);
            },
            onConfirm(value) {
                this.showPicker = false;
                this.defaultCountry = value;
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            handleClickRegister() {
                if(!this.agree){
                    this.$toast.fail(this.$t('请先阅读并同意协议'));
                    return ;
                }
                if(this.username == "" || this.username.trim() == ''){
                    this.$toast.fail(this.$t('请输入用户名'));
                    return ;
                }
                if(this.phone == "" || this.phone.trim() == ''){
                    if(this.radio == 1){
                        this.$toast.fail(this.$t('请输入Email'));
                    }else{
                        this.$toast.fail(this.$t('请输入手机号码'));
                    }
                    return ;
                }
                if(this.radio == '1'){
                    if(this.phone == "" || this.phone.trim() == ''){
                        this.$toast.fail(this.$t('请输入Email'));
                        return ;
                    }
                    if(this.mail_captcha != '1' && (this.code == "" || this.code.trim() == '')){
                        this.$toast.fail(this.$t('请输入验证码'));
                        return ;
                    }
                }else{
                    if(this.phone == "" || this.phone.trim() == ''){
                        this.$toast.fail(this.$t('请输入手机号码'));
                        return ;
                    }
                    if(this.register_captcha != '1' && (this.code == "" || this.code.trim() == '')){
                        this.$toast.fail(this.$t('请输入验证码'));
                        return ;
                    }
                }

                if(this.password == "" || this.password.trim() == ''){
                    this.$toast.fail(this.$t('请输入密码'));
                    return ;
                }
                if(this.password2 == "" || this.password2.trim() == ''){
                    this.$toast.fail(this.$t('请输入确认密码'));
                    return ;
                }
                if(this.share_code == "" || this.share_code.trim() == ''){
                    this.$toast.fail(this.$t('请输入邀请码'));
                    return ;
                }

                if (this.share_code != "" || this.username != "" || this.password != "" || this.phone != "") {
                    if (this.password != this.password2) {
                        this.$toast.fail(this.$t('两次密码输入不一致'));
                        return;
                    }
                    this.doRegister(this.username, this.phone, this.password, this.code);
                }
            },
            doRegister(username, phone, password, code) {
                this.$toast.loading();
                if(this.radio == '1'){
                    this.doRegisterMail(username, phone, password, code);
                    return ;
                }
                this.$axios
                    .post(
                        this.$api_url.register,
                        this.$qs.stringify({
                            username: username,
                            phone: phone,
                            password: password,
                            country: this.defaultCountry,
                            share_code: this.share_code,
                            captcha_key: this.captcha_key,
                            code: code,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(this.$t(res.data.msg));
                            this.$helper.setToken(this.$t(res.data.data));
                            location.href = "/#/";
                        } else {
                            this.$toast.fail(this.$t(res.data.msg));
                        }
                    })
                    .catch((ex) => {
                        this.$toast.fail(ex.message);
                    })
                    .then(() => {
                        this.captcha_code = '';
                    });
            },
            doRegisterMail(username, phone, password, code){
                this.$axios
                    .post('/register_mail', this.$qs.stringify({
                            username: username,
                            phone: phone,
                            password: password,
                            share_code: this.share_code,
                            captcha_key: this.captcha_key,
                            code: code,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(this.$t(res.data.msg));
                            this.$helper.setToken(this.$t(res.data.data));
                            setTimeout(function () {
                                location.href = "/#/";
                            }, 1000);
                        } else {
                            this.$toast.fail(this.$t(res.data.msg));
                        }
                    })
                    .catch((ex) => {
                        this.$toast.fail(ex.message);
                    });
            },
            handleClickSendCode() {
                if(this.username == "" || this.username.trim() == ''){
                    this.$toast.fail(this.$t('请输入用户名'));
                    return ;
                }
                if(this.phone == "" || this.phone.trim() == ''){
                    if(this.radio == '1'){
                        this.$toast.fail(this.$t('请输入Email'));
                    }else{
                        this.$toast.fail(this.$t('请输入手机号码'));
                    }
                    return ;
                }

                if(this.radio == '1'){
                    this.sendMail();
                }else{
                    this.sendSms();
                }
            },
            sendSms(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }

                this.send_flag = true;
                this.$axios.post(
                    this.$api_url.send_code,
                    this.$qs.stringify({
                        phone: this.phone,
                        country: this.defaultCountry,
                        captcha: this.captcha_code,
                        key: this.captcha_key,
                    })
                )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.timerInit(this.timeout_limit);
                            this.$toast.success(this.$t(res.data.data));
                        } else {
                            this.captcha_code = '';
                            this.$toast.fail(this.$t(res.data.msg));
                        }
                        this.send_flag = false;
                    }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            sendMail(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }

                this.send_flag = true;
                this.$axios.post('/send_mail',
                    this.$qs.stringify({email: this.phone})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(this.$t(res.data.msg));
                        this.timerInit(this.timeout_limit);
                    }else{
                        this.$toast.fail(this.$t(res.data.msg));
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("last_timer");
                        return;
                    }
                    this.timeout -= 1;
                    localStorage.setItem("last_timer", this.timeout);
                }, 1000);
            },
            getCaptcha() {
                this.$axios
                    .post(this.$api_url.codeimg, "")
                    .then((res) => {
                        this.captcha_img = res.data.img;
                        this.captcha_key = res.data.key;
                    })
                    .catch((err) => {
                        alert(err.message);
                    });
            },
            getCountryCode(){
                this.$axios
                    .get('/country_code?type=reg')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.allCountries = res.data.data.list;
                            this.defaultCountry = res.data.data.default;
                        }
                    });
            },
            getAgreeData() {
                this.$axios
                    .get('/get_content?type=18')
                    .then((res) => {
                        this.agreeContent = res.data
                    });
            },
        },
        created() {
            this.getCountryCode();
        },
        mounted() {
            if (this.$route.query.invite_code !== undefined) {
                this.share_code = this.$route.query.invite_code;
                this.download_url = localStorage.getItem('app.download_url') || '#';
            }
            this.register_captcha = localStorage.getItem('app.register_captcha') || 0;
            this.sms_captcha = localStorage.getItem('app.sms_captcha') || 0;
            this.mail_register = localStorage.getItem('app.mail_register') || 0;
            this.mail_captcha = localStorage.getItem('app.mail_captcha') || 0;

            this.getCaptcha();
            let time = localStorage.getItem("last_timer");
            if (time != null && time >= 0) {
                this.timerInit(time);
            }
            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.showNotify()
            this.getAgreeData()
        }
    }
</script>
<style>
    .agree-content{
        padding:50px 15px 30px 15px;
        font-size: 15px;
        line-height: 1.8;
    }
    .agreement-link{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .field-phone .van-dropdown-menu .van-dropdown-menu__bar {
        width: 70px !important;

        height: 30px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #cccccc;
    }

    .field-phone .van-dropdown-menu .van-dropdown-menu__item {
        /*border-radius: 5px;*/
    }

    .field-phone .van-dropdown-menu .van-overlay {
        background-color: unset;
        padding: 10px;
    }

    .field-phone .van-dropdown-menu .van-cell {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 0.24155rem 0.38647rem;
        overflow: hidden;
        color: #FFFFFF;
        font-size: 0.33816rem;
        line-height: 0.57971rem;
        background-color: #069150;
        margin-bottom: 0px;
    }

    .field-phone .van-dropdown-menu .van-cell:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .field-phone .van-dropdown-menu .van-cell:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .field-phone .van-dropdown-menu .van-popup {
        border-radius: 5px 5px;
        background-color: #069150;
    }

    .field-phone .van-dropdown-menu__title::after {
        border-color: transparent transparent #000000 #000000;
    }

    .field-phone .van-icon{
        font-size: 20px;
    }
    .login-form .van-cell::after{
        border-bottom: 1px solid #d1d2d3;
    }

    /*.field-phone .van-dropdown-menu .van-dropdown-item__content {*/
    /*    padding: 0px 10px;*/
    /*}*/
</style>
